@font-face {
    font-family: "ek-reg";
    src: url("./fonts/kezia-reg.ttf")
}

@font-face {
    font-family: "ek-bold";
    src: url("./fonts/ek-bold.ttf")
}

section {
    position: fixed;
    width: 100%;
    height: 100%;
}

body {
    opacity: 0.1;
    margin: 0;
    font-family: 'ek-reg', sans-serif;
    font-size: 40px;
    color: blue;
}

canvas {
    /* z-index: -1; */
}

.canvasGL {
    filter: blur(0px);
    /* position: fixed;
    top: 0;
    left: 0;
    outline: none;
    z-index: 999;
    display: block;
    visibility: hidden;
    background-color: yellow; */
    width: 100%;
    height: 100%;
    border: 1px solid white;
    display: none;
    background-color: white;
}

.canvasBio {
    /* position: fixed;
    top: 0;
    left: 0;
    outline: none;
    z-index: 999;
    display: block;
    visibility: hidden;
    background-color: yellow; */
    width: 100%;
    height: 60%;
    border: 1px solid white;
    display: none;
    margin-top: 3%;
    z-index: -1;
}

#loading {
    display: block;
    position: fixed;
    width: 70%;
    height: 100%;
    margin-top: 0%;
    padding: 5%;
    text-align: left;
    color: rgb(60, 60, 60);
    animation: blink 1.5s ease-in-out infinite;
}

#all {
    display: none;
}

#bio {
    display: none;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    color: magenta;
}

h1 {
    width: 85%;
    margin: 0.5em auto;
    padding: 0;
    color: #d20000;
    font-weight: 400;
    font-size: 50px;
    text-align: center;
}

#overlay {
    color: red;
    position: fixed;
    width: 100%;
    height: 100%;
    display: block;
    height: 100%;
}

.info {
    text-align: center;
}

.output::placeholder {
    color: rgb(181, 181, 181);
    font-size: 15px;
    font-weight: lighter;
}

.credit {
    font-size: 15px;
}

.model-status {
    color: #2d3748;
    animation: blink 1.5s ease-in-out infinite;
    margin: 0;
}

#content {
    background-image: url('https://live.staticflickr.com/2854/8858305883_32eaef4dab_b.jpg');
    justify-content: center;
    font-size: 1.5rem;
    color: #0bc5ea;
    display: block;
    width: 100%;
    height: 100%;
}

#content>img {
    margin-left: 10%;
}

#content.active {
    justify-content: center;
    font-size: 1.5rem;
    color: #0bc5ea;
    display: none;
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fade-out {
    animation: fadeOut 1s ease-in-out forwards;
    color: lightgray;
    margin: 0;
}

@keyframes fadeOut {
    from {
        opacity: 1;
        display: block;
    }
    to {
        opacity: 0;
        display: none;
    }
}

@media (max-device-width: 440px) {
    .title {
        position: fixed;
        top: -5%;
        left: 20%;
        font-size: 1rem;
    }
    #video {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        width: 15%;
        height: 15%;
        position: fixed;
        margin-left: -20%;
        margin-top: 80%;
        display: block;
    }
    #background-video {
        width: 30%;
        height: 30%;
        object-fit: cover;
        position: fixed;
        left: 50%;
        right: 0;
        top: 10%;
        bottom: 0;
        z-index: -1;
    }
    #loading {
        font-size: 1.5rem;
        margin-top: 50%;
        margin-right: 5%;
    }
    #vinylIframe,
    #vinylIframe2 {
        position: relative;
        margin-left: auto;
        margin-right: auto;
        width: 150px;
        height: 150px;
    }
    .menu {
        margin-left: -30%;
    }
    #table {
        position: fixed;
        margin-top: 55%;
        margin-left: -25%;
        width: 360px;
        height: 240px;
        opacity: 1;
    }
    #table.active {
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all 1000ms;
    }
    #pc {
        position: fixed;
        margin-top: 50%;
        margin-left: -10%;
        width: 150px;
        height: 150px;
    }
    #radio {
        position: fixed;
        margin-top: 80%;
        margin-left: 20%;
        width: 250px;
        height: 250px;
    }
    #person {
        position: fixed;
        margin-top: 100%;
        margin-left: -30%;
        width: 150px;
        height: 150px;
    }
    #exhibition {
        position: fixed;
        margin-top: 10%;
        margin-left: -20%;
        width: 80px;
    }
    #pcWindow {
        width: 100%;
        height: 100%;
        display: none;
    }
    #urlBox {
        border: 1px solid black;
        background-color: grey;
        height: 10%;
    }
    #pcContent {
        display: inline;
        height: 100%;
    }
    #iframeBox {
        border-bottom: 1px solid black;
        display: flex;
        width: 100%;
        height: 70%;
    }
    #console {
        width: 100%;
        height: 30%;
        color: rgb(60, 60, 60);
        font-size: 12px;
    }
    .dropbtn {
        color: black;
        font-family: 'Courier New', Courier, monospace;
        padding: 10px;
        width: 25%;
        font-size: 16px;
        border: none;
        position: relative;
    }
}


/* @media (min-device-width: 300px) {
    #vinylIframe,
    #vinylIframe2 {
        position: relative;
        margin-left: auto;
        margin-right: auto;
        width: 150px;
        height: 150px;
    }
    .menu {
        margin-left: -30%;
    }
    #table {
        position: fixed;
        margin-top: 60%;
        margin-left: -25%;
        width: 360px;
        height: 240px;
        opacity: 1;
    }
    #table.active {
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all 1000ms;
    }
    #pc {
        position: relative;
        margin-top: 45%;
        width: 200px;
        height: 200px;
    }
    #radio {
        position: relative;
        margin-top: 50%;
        margin-left: -30%;
        width: 250px;
        height: 250px;
    }
    #person {
        position: fixed;
        margin-top: 20%;
        margin-left: -20%;
        width: 250px;
        height: 250px;
    }
    #pcWindow {
        width: 100%;
        height: 100%;
        display: none;
        background-color: red;
    }
    #urlBox {
        background-color: pink;
        height: 5%;
    }
    #pcContent {
        display: inline;
        height: 100%;
    }
    #iframeBox {
        display: flex;
        width: 100%;
        height: 70%;
        background-color: red;
    }
    #console {
        background-color: blue;
        width: 100%;
        height: 30%;
        color: white;
        font-size: 12px;
    }
    .dropbtn {
        color: white;
        padding: 10px;
        width: 25%;
        font-size: 16px;
        border: none;
        position: relative;
    }
} */

@media (min-device-width: 440px) {
    #vinylIframe,
    #vinylIframe2 {
        position: relative;
        margin-left: auto;
        margin-right: auto;
        width: 150px;
        height: 150px;
    }
    .menu {
        margin-left: -30%;
    }
    #table {
        position: fixed;
        margin-top: 150%;
        margin-left: -25%;
        width: 360px;
        height: 240px;
        opacity: 1;
    }
    #table.active {
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all 1000ms;
    }
    #pc {
        position: fixed;
        margin-top: 45%;
        width: 200px;
        height: 200px;
    }
    #radio {
        position: fixed;
        margin-top: 0%;
        margin-left: 10%;
        width: 250px;
        height: 250px;
    }
    #person {
        position: fixed;
        margin-top: 50%;
        margin-left: 0%;
        width: 250px;
        height: 250px;
    }
}


/* Tablet Landscape */

@media (min-device-width: 768px) {
    #video {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        width: 15%;
        height: 15%;
        position: fixed;
        margin-left: -18%;
        margin-top: 22%;
        display: block;
    }
    #background-video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: fixed;
        left: 50%;
        right: 0;
        top: -0%;
        bottom: 0;
        z-index: -1;
    }
    main {
        display: flex;
        max-height: 100%;
        height: 70%;
        align-items: center;
        justify-content: center;
        margin-top: 0;
    }
    #table {
        position: fixed;
        margin-top: 20%;
        margin-left: -30%;
        width: 600px;
        height: 400px;
        opacity: 1;
    }
    #table.active {
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all 1000ms;
    }
    #pc {
        position: fixed;
        margin-top: 5%;
        width: 200px;
        height: 200px;
    }
    #radio {
        position: fixed;
        margin-top: 0%;
        margin-left: 30%;
        width: 300px;
        height: 300px;
    }
    #exhibition {
        position: fixed;
        margin-left: -20%;
    }
    #person {
        position: fixed;
        margin-top: 40%;
        margin-left: -20%;
        width: 250px;
        height: 250px;
    }
    #pcWindow {
        width: 100%;
        height: 100%;
        display: none;
    }
    #urlBox {
        border: 1px solid black;
        height: 10%;
    }
    #pcContent {
        display: flex;
        height: 100%;
    }
    #iframeBox {
        border-right: 1px solid black;
        display: flex;
        width: 70%;
        height: 100%;
        background-color: black;
    }
    #console {
        width: 30%;
        height: 100%;
        padding: 2%;
        color: rgb(60, 60, 60);
    }
    .dropbtn {
        color: black;
        font-family: 'Courier New', Courier, monospace;
        padding: 16px;
        width: 25%;
        font-size: 20px;
        border: none;
        position: relative;
    }
}

@media (min-device-width: 1025px) {
    .title {
        position: fixed;
        top: 0%;
        left: 40%;
        font-size: 2rem;
    }
    #background-video {
        width: 50%;
        height: 50%;
        object-fit: cover;
        position: fixed;
        left: 25%;
        right: 0;
        top: 10%;
        bottom: 0;
        z-index: -1;
    }
    #vinylIframe,
    #vinylIframe2 {
        position: relative;
        margin-left: auto;
        margin-right: auto;
        width: 400px;
        height: 400px;
    }
    #table {
        position: fixed;
        margin-top: 15%;
        margin-left: -10%;
        width: 600px;
        height: 400px;
        opacity: 1;
    }
    #table.active {
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all 1000ms;
    }
    #pc {
        position: fixed;
        margin-top: 15%;
        margin-left: 0%;
        width: 200px;
        height: 200px;
    }
    #person {
        position: fixed;
        margin-top: 30%;
        margin-left: -20%;
        width: 250px;
        height: 250px;
    }
    #radio {
        position: fixed;
        margin-top: 20%;
        margin-left: 30%;
        width: 300px;
        height: 300px;
    }
    h1 {
        padding-top: 1em;
    }
    main {
        display: flex;
        min-height: 80%;
        max-height: 100%;
        height: 80%;
        align-items: center;
        justify-content: center;
        margin-top: 0;
    }
    #pcWindow {
        width: 100%;
        height: 100%;
        display: none;
    }
    #urlBox {
        border: 1px solid black;
        background-color: grey;
    }
    #pcContent {
        display: flex;
        height: 100%;
    }
    #iframeBox {
        border-right: 1px solid black;
        display: flex;
        width: 70%;
        height: 100%;
        background-color: rgb(136, 27, 27);
    }
    #console {
        width: 30%;
        height: 100%;
        color: rgb(60, 60, 60);
    }
    .dropbtn {
        color: black;
        font-family: 'Courier New', Courier, monospace;
        padding: 16px;
        width: 25%;
        font-size: 20px;
        border: none;
        position: relative;
    }
}

.menu {
    margin-left: 30%;
}

#cv {
    position: relative;
    display: inline-block;
    width: 100%;
}

.dropdown {
    position: relative;
    width: 100%;
    display: flex;
    align-items: left;
}

.dropdown-content {
    display: none;
    position: absolute;
    width: 25%;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #ddd;
}

.dropbtn:hover>.dropdown-content {
    display: block;
}

.dropbtn:hover>.dropbtn {
    background-color: #3e8e41;
}

.webDesc {
    font-family: 'Courier New', Courier, monospace;
    font-size: 1rem;
    margin: 5%;
}

#hellokol-desc {
    display: block;
}

#markuqueue-desc {
    display: none;
}

#katalok-desc {
    display: none;
}

#back {
    display: none;
    position: sticky;
    margin-left: 2%;
    font-size: 5rem;
}

#backPhoto {
    display: none;
    position: sticky;
    margin-left: 2%;
    font-size: 5rem;
}

#back:hover {
    animation: blink 1s infinite;
    color: red;
}

#backPhoto:hover {
    animation: blink 1s infinite;
    color: blue;
}


/* Scrolling */

#scroll-container {
    border: 2px solid black;
    border-radius: 1px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    position: relative;
    height: 10%;
    margin-top: -5%;
    overflow-y: scroll;
}

#scroll-text {
    height: 200%;
    text-align: center;
    /* animation properties */
    -moz-transform: translateY(100%);
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -moz-animation: my-animation 10s linear;
    -webkit-animation: my-animation 10s linear;
    animation: my-animation 1-s linear;
    animation-iteration-count: 1;
}


/* for Firefox */

@-moz-keyframes my-animation {
    from {
        -moz-transform: translateY(100%);
    }
    to {
        -moz-transform: translateY(-200%);
    }
}


/* for Chrome */

@-webkit-keyframes my-animation {
    from {
        -webkit-transform: translateY(100%);
    }
    to {
        -webkit-transform: translateY(-200%);
    }
}

@keyframes my-animation {
    from {
        -moz-transform: translateY(100%);
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }
    to {
        -moz-transform: translateY(-200%);
        -webkit-transform: translateY(-200%);
        transform: translateY(-200%);
    }
}


/* CSS */

button {
    z-index: 999;
}

.button-14 {
    background-image: linear-gradient(#f7f8fa, #e7e9ec);
    border-color: #adb1b8 #a2a6ac #8d9096;
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
    box-shadow: rgba(255, 255, 255, .6) 0 1px 0 inset;
    box-sizing: border-box;
    color: #0f1111;
    cursor: pointer;
    display: inline-block;
    font-family: 'Courier New', Courier, monospace;
    height: 5%;
    font-size: 20px;
    outline: 0;
    overflow: hidden;
    padding: 0% 2%;
    text-align: center;
    text-decoration: none;
    text-overflow: ellipsis;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    z-index: 999;
}

.button-14:active {
    border-bottom-color: #a2a6ac;
}

.button-14:active:hover {
    border-bottom-color: #a2a6ac;
}

.button-14:hover {
    color: pink;
    border-color: #a2a6ac #979aa1 #82858a;
}

.button-14:focus {
    border-color: #e77600;
    box-shadow: rgba(228, 121, 17, .5) 0 0 3px 2px;
    outline: 0;
}

.mainImg {
    display: inline;
    width: 100px;
    position: relative;
    height: auto;
    z-index: 998;
}

img {
    /* width: 100px; */
    display: block;
}

.mainImg:hover {
    margin-left: auto;
    margin-right: auto;
    width: 500px;
    align-items: middle;
    height: auto;
    z-index: 998;
}

.images {
    margin-left: auto;
    margin-right: auto;
}

#images1,
#images2,
#images3,
#images4,
#images5,
#images6,
#images7,
#images8,
#images9,
#images10,
#images11 {
    display: none;
}

#photoWindow {
    display: none;
    z-index: 999;
    height: 100%;
}

#exhibitionWindow {
    display: none;
    z-index: 999;
    height: 100%;
    padding: 2%;
    color: green;
}

#photoDesc {
    position: fixed;
    right: 5%;
    bottom: 0;
    margin-left: 40%;
    width: 50%;
    color: blue;
    font-size: 1.5rem;
    z-index: 999;
    font-family: 'Courier New', Courier, monospace;
}

#photoDesc:hover {
    color: red;
}

.draggable {
    width: 25%;
    min-height: 6.5em;
    margin: 1rem 0 0 1rem;
    background-color: #29e;
    color: white;
    border-radius: 0.75em;
    padding: 4%;
    touch-action: none;
    user-select: none;
    z-index: 999;
}

#cvContent {
    display: none;
    width: 100%;
    margin-top: 0%;
    font-family: 'Courier New', Courier, monospace;
    color: rgba(60, 60, 60);
}

table,
th,
td {
    border: 1px solid;
}

table {
    width: 100%;
}

input {
    width: 60%;
    padding: 12px 20px;
    margin: 8px 0;
    display: flex;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-family: 'Courier New', Courier, monospace;
}

form {
    width: 100%;
}

.column {
    float: left;
    width: 50%;
    font-size: 20px;
}

ul {
    list-style-type: upper-roman;
}

h1 {
    font-family: "ek-bold", 'Courier New', Courier, monospace
}

p {
    font-family: "ek-reg", 'Courier New', Courier, monospace
}


/* Clear floats after the columns */

.row:after {
    content: "";
    display: table;
    clear: both;
}

#mydiv {
    position: absolute;
    z-index: 9;
    background-color: #f1f1f1;
    border: 1px solid #d3d3d3;
    text-align: center;
}

#mydivheader {
    padding: 10px;
    cursor: move;
    z-index: 10;
    background-color: #2196F3;
    color: #fff;
}

#dj {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    height: 100px;
    padding: 10px;
    border: 1px solid black;
}

#record1 {
    /* padding-right: 30%; */
}

#music {
    display: none;
    z-index: 999;
    color: red;
}

#table:hover~#title_photo {
    display: block;
}

#pc:hover~#title_coding {
    display: block;
}

#radio:hover~#title_audio {
    display: block;
}

#exhibition:hover~#title_exhibition {
    display: block;
}

#person:hover~#title_me {
    display: block;
}

#title_photo,
#title_audio,
#title_coding,
#title_exhibition,
#title_me {
    display: none;
    top: 0;
    position: fixed;
    left: 0;
}

#title_exhibition {
    color: green;
}

#title_photo {
    color: blue;
}

#title_coding {
    color: rgba(60, 60, 60);
}

#title_me {
    color: magenta;
}

#title_audio {
    color: red;
}

#record.rotate,
#record2.rotate {
    animation: rotation 2s infinite linear;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.grid-container {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
    padding: 10px;
    z-index: 999;
    position: relative;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.grid-container>div {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid black;
    text-align: center;
    font-size: 30px;
}

form {
    display: flex;
    width: 100%;
}

#exhibitionDesc {
    font-family: 'Courier New', Courier, monospace;
    font-size: 1rem;
}

audio {
    z-index: 999;
}
